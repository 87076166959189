/**
 * @generated SignedSource<<8b94ac8d695398a469798f82c7403534>>
 * @relayHash 6be6356e200aed82540809286e490189
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID app-buyer-misc/498.0.0-2025-02-14T17:01:14.280Z/SharedFavoritesProviderRefetchQuery

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SharedFavoritesProviderRefetchQuery$variables = {
  loadPortfolioData?: boolean | null;
  selectedItemIds?: ReadonlyArray<string | null> | null;
  shouldFillInFolder?: boolean | null;
  userIds?: ReadonlyArray<string | null> | null;
};
export type SharedFavoritesProviderRefetchQuery$data = {
  readonly viewer: {
    readonly " $fragmentSpreads": FragmentRefs<"SharedFavoritesProvider_viewer">;
  };
};
export type SharedFavoritesProviderRefetchQuery = {
  response: SharedFavoritesProviderRefetchQuery$data;
  variables: SharedFavoritesProviderRefetchQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": false,
  "kind": "LocalArgument",
  "name": "loadPortfolioData"
},
v1 = {
  "defaultValue": ([]/*: any*/),
  "kind": "LocalArgument",
  "name": "selectedItemIds"
},
v2 = {
  "defaultValue": false,
  "kind": "LocalArgument",
  "name": "shouldFillInFolder"
},
v3 = {
  "defaultValue": ([]/*: any*/),
  "kind": "LocalArgument",
  "name": "userIds"
},
v4 = {
  "kind": "Variable",
  "name": "userIds",
  "variableName": "userIds"
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v7 = {
  "kind": "Variable",
  "name": "itemIds",
  "variableName": "selectedItemIds"
},
v8 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "serviceId",
    "storageKey": null
  },
  (v5/*: any*/)
],
v9 = {
  "alias": null,
  "args": null,
  "concreteType": "Item",
  "kind": "LinkedField",
  "name": "item",
  "plural": false,
  "selections": (v8/*: any*/),
  "storageKey": null
},
v10 = {
  "kind": "Literal",
  "name": "portfolioTypes",
  "value": [
    "HEART"
  ]
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "SharedFavoritesProviderRefetchQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "args": [
              {
                "kind": "Variable",
                "name": "loadPortfolioData",
                "variableName": "loadPortfolioData"
              },
              {
                "kind": "Variable",
                "name": "selectedItemIds",
                "variableName": "selectedItemIds"
              },
              {
                "kind": "Variable",
                "name": "shouldFillInFolder",
                "variableName": "shouldFillInFolder"
              },
              (v4/*: any*/)
            ],
            "kind": "FragmentSpread",
            "name": "SharedFavoritesProvider_viewer"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "RootQueryType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v3/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Operation",
    "name": "SharedFavoritesProviderRefetchQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v5/*: any*/),
          (v6/*: any*/),
          {
            "condition": "shouldFillInFolder",
            "kind": "Condition",
            "passingValue": true,
            "selections": [
              {
                "alias": "folderItemMatches",
                "args": [
                  (v7/*: any*/),
                  {
                    "kind": "Literal",
                    "name": "portfolioTypes",
                    "value": [
                      "FOLDER"
                    ]
                  },
                  (v4/*: any*/)
                ],
                "concreteType": "PortfolioItemV2",
                "kind": "LinkedField",
                "name": "portfolioItemMatches",
                "plural": true,
                "selections": [
                  (v6/*: any*/),
                  (v9/*: any*/),
                  (v5/*: any*/)
                ],
                "storageKey": null
              }
            ]
          },
          {
            "condition": "loadPortfolioData",
            "kind": "Condition",
            "passingValue": true,
            "selections": [
              {
                "alias": "heartItemMatches",
                "args": [
                  (v7/*: any*/),
                  (v10/*: any*/),
                  (v4/*: any*/)
                ],
                "concreteType": "PortfolioItemV2",
                "kind": "LinkedField",
                "name": "portfolioItemMatches",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "portfolioId",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "portfolioItemId",
                    "storageKey": null
                  },
                  (v9/*: any*/),
                  (v5/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": "userPortfolios",
                "args": [
                  {
                    "kind": "Literal",
                    "name": "first",
                    "value": 1
                  },
                  (v10/*: any*/),
                  (v4/*: any*/)
                ],
                "concreteType": "PortfoliosConnection",
                "kind": "LinkedField",
                "name": "portfolios",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PortfoliosEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "PortfolioV2",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": (v8/*: any*/),
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ]
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": "app-buyer-misc/498.0.0-2025-02-14T17:01:14.280Z/SharedFavoritesProviderRefetchQuery",
    "metadata": {},
    "name": "SharedFavoritesProviderRefetchQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "23bcf7685f5f39a1e2b939714513711c";

export default node;
